::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #181818;
}
::-webkit-scrollbar-thumb {
  background: rgb(48, 48, 48);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(73, 73, 73);
}

.almost-full-height {
  height: 90% !important;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.fake-label,
label {
  width: 50%;
  color: #ffffff;
  margin: 0.5rem 0;
  text-align: center;
  z-index: 2;
}

.fake-label {
  width: 75%;
}

.error-info,
.result-info {
  font-size: 0.85rem;
}

.result-info {
  position: absolute;
  right: 0.5rem;
  bottom: 0;
  width: min-content;
}

.error-info {
  color: red;
  text-shadow: 0 0 20px black, 0 0 20px red;
  position: fixed;
  top: 0.5rem;
  border: 2px solid red;
  padding: 1rem;
  border-radius: 4px;
  border-left-width: 16px;
  border-right-width: 16px;
  background-color: rgb(0, 0, 0);
  z-index: 11;
}

#at-expanding-share-button {
  display: none;
  opacity: 0.5;
  transition: 0.3s ease-in-out;
}
#at-expanding-share-button:hover {
  opacity: 0.9;
}

.inputs {
  position: sticky;
  top: 0rem;
  z-index: 1;
}
.inputs.invisible {
  height: 2rem;
  width: 96%;
  margin-bottom: 1.5rem;
}
.inputs.invisible .toggle-input-fields span {
  display: inline-block;
}
.inputs.invisible .toggle-input-fields path {
  transform: rotate(900deg);
}
.inputs.invisible .inputs-content {
  animation: mymove 0.5s ease-in-out;
  padding: 1.5rem;
}
.inputs.invisible .inputs-content * {
  opacity: 0;
  height: 0;
  margin: 0;
  padding: 0;
}
@keyframes mymove {
  0% {
    height: 20rem;
  }
  100% {
    height: 2rem;
  }
}
.inputs-header {
  position: relative;
  z-index: 2;
}
.inputs-header .toggle-input-fields {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  background-color: transparent;
  border: 2px solid #515153;
  padding: 0.5rem;
  color: #ffffff;
  transition: 0.2s ease-in-out;
}
.inputs-header .toggle-input-fields span {
  display: none;
  margin-left: 1rem;
}
.inputs-header .toggle-input-fields svg path {
  transform-origin: center;
  transition: 0.5s ease-in-out;
}
.inputs-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 1rem;
  padding: 0.5rem;
  max-width: 30rem;
  box-shadow: 1px 1px 15px 0px #000;
  background-color: #121213;
  transition: height 0.2s ease-in-out;
}
.inputs-content input {
  border: 2px solid #515153;
  width: 60%;
  margin: 0 auto;
  font-size: 1.5rem;
  padding: 0.25rem;
  text-transform: uppercase;
  text-align: center;
  background-color: transparent;
  color: #ffffff;
  box-shadow: 1px 1px 15px 0px #000;
  transition: 0.2s ease-in-out;
}
.inputs-content input.no-valid {
  background-color: #ff0000 !important;
  z-index: 100;
  animation: redShadowPulse 1.5s linear infinite;
  text-decoration: underline;
}
.inputs-content input:disabled {
  filter: saturate(0.25);
  opacity: 0.2;
}
.inputs-content .positional {
  display: flex;
  width: 53%;
  justify-content: center;
}
.inputs-content .positional input {
  text-align: center;
  text-transform: uppercase;
  padding: 0;
  padding: 0.2rem;
  font-size: 1.75em;
  margin: 0.1rem;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  color: #ffffff;
}
.inputs-content .positional input.has-value {
  background-color: #538D4E;
}
.inputs-content .positional.incorrect input.number-of-letters--1 {
  font-size: 1.75rem;
}
.inputs-content .positional.incorrect input.number-of-letters--2 {
  font-size: 1.5rem;
}
.inputs-content .positional.incorrect input.number-of-letters--3 {
  font-size: 1rem;
}
.inputs-content .positional.incorrect input.number-of-letters--4 {
  font-size: 0.75rem;
}
.inputs-content .positional.incorrect input.number-of-letters--1, .inputs-content .positional.incorrect input.number-of-letters--2, .inputs-content .positional.incorrect input.number-of-letters--3, .inputs-content .positional.incorrect input.number-of-letters--4 {
  background-color: #B59F3B;
}
@media only screen and (min-width: 630px) {
  .inputs-content {
    padding: 1rem 1rem 2.5rem 1rem;
  }
  .inputs-content .positional input {
    font-size: 2.5em;
    width: 3rem;
    height: 3rem;
  }
  .inputs-content .positional.incorrect input.number-of-letters--1 {
    font-size: 2.5rem;
  }
  .inputs-content .positional.incorrect input.number-of-letters--2 {
    font-size: 2rem;
  }
  .inputs-content .positional.incorrect input.number-of-letters--3 {
    font-size: 1.5rem;
  }
  .inputs-content .positional.incorrect input.number-of-letters--4 {
    font-weight: bold;
    font-size: 1rem;
  }
  .inputs-content .positional.incorrect input.number-of-letters--1, .inputs-content .positional.incorrect input.number-of-letters--2, .inputs-content .positional.incorrect input.number-of-letters--3, .inputs-content .positional.incorrect input.number-of-letters--4 {
    background-color: #B59F3B;
  }
  .inputs-content input {
    padding: 0.75rem;
    font-size: 1.5em;
  }
}

.loading .inputs {
  opacity: 0.3;
  box-shadow: 1px 1px 2px 0px #000;
  animation: pulse 1.5s infinite ease-in-out;
}
.loading .inputs .inputs-content {
  background-color: transparent;
}
.loading .inputs .positional, .loading .inputs input {
  box-shadow: none;
  border: 2px solid rgba(255, 255, 255, 0.5);
}
.loading .inputs .positional {
  opacity: 0.5;
  animation: pulse 1.5s infinite ease-in-out;
  height: 3rem;
}
.loading .inputs .fake-label,
.loading .inputs label {
  opacity: 0;
}

@keyframes pulse {
  0% {
    filter: contrast(0) brightness(0.5);
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    filter: contrast(0) brightness(0.75);
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    filter: contrast(0) brightness(0.5);
    background-color: rgba(165, 165, 165, 0.1);
  }
}
@keyframes redShadowPulse {
  0% {
    box-shadow: 0px 0px 3px red;
  }
  50% {
    box-shadow: 0px 0px 15px red;
  }
  100% {
    box-shadow: 0px 0px 3px red;
  }
}
.result {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 50rem;
  max-width: 80vw;
  align-items: center;
  margin-top: 0;
  min-height: 10rem;
  height: auto;
  padding: 0rem 1rem 6rem 1rem;
  overflow-x: hidden;
  overflow-y: hidden;
}
.result .word {
  transition: opacity 0.1s ease-in-out, transform 0.4s ease-in-out;
  color: #ffffff;
  opacity: 0;
  padding: 0.75rem 0;
  display: flex;
  justify-content: space-between;
  max-height: 4rem;
  margin-right: 0.25rem;
  transform: translateY(0px);
}
.result .word .letter {
  background-color: #121213;
  border: 2px solid #515153;
  padding: 0.5rem;
  font-size: 0.75em;
  box-shadow: 7px 8px 5px #000;
}
.result .word .letter:last-child {
  margin-right: 0.5rem;
}
.result .word .letter:not(:last-child) {
  border-right: none;
}
.result .word.in-bottom {
  transform: translateX(1000px);
}
.result .word.magic-word .letter:not(:last-child) {
  margin-left: 0.25rem;
  border: none;
  font-weight: bold;
}
.result .word.magic-word .letter:not(:last-child):nth-child(1), .result .word.magic-word .letter:not(:last-child):nth-child(4), .result .word.magic-word .letter:not(:last-child):nth-child(8) {
  background-color: #538D4E;
}
.result .word.magic-word .letter:not(:last-child):nth-child(5), .result .word.magic-word .letter:not(:last-child):nth-child(6) {
  background-color: #B59F3B;
}
.result.visible .word {
  opacity: 1;
}
@media only screen and (min-width: 1100px) {
  .result {
    margin-top: 3rem;
    height: 42rem;
    min-height: 20rem;
    padding: 1rem 1rem 1rem 1rem;
    overflow-y: scroll;
  }
}
@media only screen and (min-width: 450px) {
  .result {
    padding: 0rem 1rem 2rem 1rem;
  }
  .result .word .letter {
    font-size: 0.85em;
    padding: 0.7rem;
  }
}
@media only screen and (min-width: 630px) {
  .result .word .letter {
    font-size: 1em;
    padding: 0.9rem;
  }
}

.loading .result {
  justify-content: flex-start;
  height: unset;
  margin-top: 2rem;
  overflow-y: hidden;
}
.loading .result .word {
  margin-left: 1rem;
  margin-bottom: 0.75rem;
  opacity: 0.3;
  width: 60%;
  animation: pulse 1.5s infinite ease-in-out;
}
.loading .result .word:nth-child(2n+1) {
  width: 50%;
  animation: pulse 2.5s infinite ease-in-out;
}
.loading .result .word:nth-child(3n) {
  width: 70%;
  animation: pulse 2s infinite ease-in-out;
}
@media only screen and (min-width: 1100px) {
  .loading {
    margin-top: unset;
  }
}

@keyframes pulse {
  0% {
    filter: contrast(0) brightness(0.5);
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    filter: contrast(0) brightness(0.75);
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    filter: contrast(0) brightness(0.5);
    background-color: rgba(165, 165, 165, 0.1);
  }
}
@keyframes redShadowPulse {
  0% {
    box-shadow: 0px 0px 3px red;
  }
  50% {
    box-shadow: 0px 0px 15px red;
  }
  100% {
    box-shadow: 0px 0px 3px red;
  }
}
header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.25rem;
  justify-content: center;
}
header .logo {
  width: 100%;
  margin: 1rem 1rem 0.25rem 1rem;
  display: flex;
  flex-direction: column;
}
header .logo-image {
  width: 20rem;
  height: auto;
  z-index: 10;
  transition: 0.2s ease-in-out;
  opacity: 0.7;
}
header .logo-text {
  opacity: 0;
  height: 0;
  width: 0;
}
header .logo .logo-slogan {
  font-size: 1rem;
  color: #6b6b6b;
  font-family: Lucida Handwriting;
  text-align: left;
  margin: 0.25rem 0 0 0.5rem;
}
@media only screen and (min-width: 665px) {
  header {
    justify-content: space-between;
  }
  header .logo {
    width: unset;
    margin-bottom: 1rem;
  }
}
header .toggle-buttons {
  z-index: 10;
}
header .toggle-buttons button {
  margin: 0.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.75em;
  background-color: #121213;
  color: #ffffff;
  border: 2px solid #515153;
  box-shadow: 1px 1px 15px 0px #000;
  transition: 0.2s ease-in-out;
}
header .toggle-buttons button.active {
  box-shadow: 0px 0px 9px rgba(0, 128, 0, 0.6588235294);
  border-bottom-color: #008000;
  offset-path: 2px #ff0000;
  outline-style: dotted;
  outline-width: 3px;
  outline-color: #008000;
  outline-offset: 3px;
}
header .toggle-buttons button:active, header .toggle-buttons button:focus, header .toggle-buttons button:hover {
  box-shadow: 0px 0px 9px rgba(0, 128, 0, 0.6588235294);
}
@media only screen and (min-height: 630px) {
  header .toggle-buttons button {
    padding: 0.5rem 1rem;
    font-size: 1em;
  }
}
@media only screen and (min-width: 630px) {
  header .toggle-buttons {
    margin: 1rem;
  }
}
header h1,
header h2 {
  margin: 0;
}

.loading header {
  opacity: 0.3;
  animation: pulse 1.5s infinite ease-in-out;
  margin: 0;
}
.loading header .logo {
  opacity: 0.3;
}
.loading header .logo-image {
  animation: pulse 1.5s infinite ease-in-out;
}
.loading header .logo .logo-slogan {
  color: rgba(0, 0, 0, 0);
  animation: pulse 1.5s infinite ease-in-out;
}
@media only screen and (min-width: 665px) {
  .loading header {
    justify-content: space-between;
  }
  .loading header .logo {
    width: unset;
  }
}
.loading header .toggle-buttons {
  opacity: 0.2;
}
.loading header .toggle-buttons button {
  animation: pulse 1.5s infinite ease-in-out;
  color: rgba(0, 0, 0, 0);
}
.loading header .toggle-buttons button.active {
  outline: none;
}
@media only screen and (min-height: 630px) {
  .loading header .toggle-buttons button {
    padding: 0.75rem 1.5rem;
    font-size: 1em;
  }
}

@keyframes pulse {
  0% {
    filter: contrast(0) brightness(0.5);
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    filter: contrast(0) brightness(0.75);
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    filter: contrast(0) brightness(0.5);
    background-color: rgba(165, 165, 165, 0.1);
  }
}
@keyframes redShadowPulse {
  0% {
    box-shadow: 0px 0px 3px red;
  }
  50% {
    box-shadow: 0px 0px 15px red;
  }
  100% {
    box-shadow: 0px 0px 3px red;
  }
}
footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #ffffff;
  background-color: #161616;
  margin: -1rem;
  padding: 0.5rem 0 1.5rem 0;
  bottom: 0rem;
  position: fixed;
  left: 0;
  right: 0;
  box-shadow: 0px -10px 16px rgba(0, 0, 0, 0.3411764706);
  font-size: 0.75rem;
  transition: 0.2s ease-in-out;
}
footer .links {
  display: flex;
  align-items: center;
}
footer .made-by {
  word-break: keep-all;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
footer .made-by a {
  color: #ffffff;
}
footer .made-by a svg {
  width: 1.5rem;
  height: 1.5rem;
}
footer a, footer span {
  margin: 0 0.5rem;
  color: #ffffff;
}
@media only screen and (min-width: 500px) {
  footer {
    font-size: 1rem;
    justify-content: space-evenly;
  }
}

.loading footer {
  opacity: 0.2;
  animation: pulse 1.5s infinite ease-in-out;
  color: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.loading footer .made-by {
  opacity: 0.5;
  animation: pulse 1.5s infinite ease-in-out;
}
.loading footer .links a {
  opacity: 0.5;
  animation: pulse 1.5s infinite ease-in-out;
  color: rgba(0, 0, 0, 0);
}

@keyframes pulse {
  0% {
    filter: contrast(0) brightness(0.5);
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    filter: contrast(0) brightness(0.75);
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    filter: contrast(0) brightness(0.5);
    background-color: rgba(165, 165, 165, 0.1);
  }
}
@keyframes redShadowPulse {
  0% {
    box-shadow: 0px 0px 3px red;
  }
  50% {
    box-shadow: 0px 0px 15px red;
  }
  100% {
    box-shadow: 0px 0px 3px red;
  }
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121213;
}
body .App {
  padding: 0rem 1rem 1rem;
  height: 70vh;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  transition: 0.5s ease-in-out;
}
body .App.invisible {
  opacity: 0;
}
body .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
}
body .loading .wrapper {
  height: 100%;
}
@media only screen and (min-height: 630px) {
  body .App {
    padding: 1rem;
  }
}
@media only screen and (max-width: 1100px) {
  body .wrapper {
    flex-direction: column;
    height: unset;
  }
  body .wrapper .result .word.in-bottom {
    transform: translateY(500px);
  }
  body .loading .wrapper {
    justify-content: flex-start;
    margin-top: 2rem;
  }
}

