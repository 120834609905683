// Scrollbar
::-webkit-scrollbar {
  width: 10px;
  &-track {
    background: #181818;
  }
  &-thumb {
    background: rgb(48, 48, 48);
    border-radius: 10px;
    &:hover {
      background: rgb(73, 73, 73);
    }
  }
}

// Utils
.almost-full-height {
  height: 90% !important;
}
// Screenreader only
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.fake-label,
label {
  width: 50%;
  color: $font-color;
  margin: 0.5rem 0;
  text-align: center;
  z-index: 2;
}
.fake-label {
  width: 75%;
}
.error-info,
.result-info {
  font-size: 0.85rem;
}
.result-info {
  position: absolute;
  right: 0.5rem;
  bottom: 0;
  width: min-content;
}
.error-info {
  color: red;
  text-shadow: 0 0 20px black, 0 0 20px red;
  position: fixed;
  top: 0.5rem;
  border: 2px solid red;
  padding: 1rem;
  border-radius: 4px;
  border-left-width: 16px;
  border-right-width: 16px;
  background-color: rgba(0,0,0,1);
  z-index: 11;
}