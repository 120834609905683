// Colors

$bg-color: #121213;
$bg-color--footer: #161616;
$bg-color--add-this: #1a1a1a;
$font-color: #ffffff;
$border-color: #515153;
$font-color--slogan: #6b6b6b;;

$accent-1: #202020;
$accent-2: #a6a8cd;
$accent-3: #350000;
$accent-4: #757687;

$color-correct: #538D4E;
$color-missplaced: #B59F3B;

$black: #000;
$red: #ff0000;

$box-shadow-color--green: #008000a8;
$border-color--green: #008000;


$font-family--tiles: 'ClearSans-Bold', 'Helvetica Neue','Arial';
$font--slogan: Lucida Handwriting;
