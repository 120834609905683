// @mixin font($font-family, $font-file) {
//   @font-face {
//     font-family: $font-family;
//     src: url($font-file+'.woff') format('woff'),
//          url($font-file+'.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
//   }
// }



// @include font('Clear Sans-Bold', './fonts/ClearSans-Bold');
// // @include font('Arvo-Bold', '/htdocs/lib/fonts/Arvo-Bold');


@import "./General/_variables.scss";
@import "./General/_fixes.scss";
@import "./General/_mixins.scss";

@import "./Components/_add-this.scss";
@import "./Components/_input-area.scss";
@import "./Components/_result-area.scss";

@import "./Sections/_header.scss";
@import "./Sections/_footer.scss";
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;

  .App {
    padding: 0rem 1rem 1rem;
    height: 70vh;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    transition: 0.5s ease-in-out;
  }

  .App.invisible {
    opacity: 0;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 100%;
  }
  .loading .wrapper {
    height: 100%;
  }

  @media only screen and (min-height: 630px) {
    .App {
      padding: 1rem;
    }
  }
  @media only screen and (max-width: 1100px) {
    .wrapper {
      flex-direction: column;
      height: unset;
      .result {
        .word {
          &.in-bottom {
            transform: translateY(500px);
          }
        }
      }
    }
    .loading {
      .wrapper {
        justify-content: flex-start;
        margin-top: 2rem;
      }
    }
  }
}
