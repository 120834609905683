.result {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 50rem;
  max-width: 80vw;
  align-items: center;
  margin-top: 0;
  min-height: 10rem;
  height: auto;
  padding: 0rem 1rem 6rem 1rem;
  overflow-x: hidden;
  overflow-y: hidden;
  .word {
    transition: opacity 0.1s ease-in-out, transform 0.4s ease-in-out;
    color: $font-color;
    opacity: 0;
    padding: 0.75rem 0;
    display: flex;
    justify-content: space-between;
    max-height: 4rem;
    margin-right: 0.25rem;
    transform: translateY(0px);
    .letter {
      background-color: $bg-color;
      border: 2px solid $border-color;
      padding: 0.5rem;
      font-size: 0.75em;

      box-shadow: 7px 8px 5px $black;
      &:last-child {
        margin-right: 0.5rem;
      }
      &:not(:last-child) {
        border-right: none;
      }
    }
    &.in-bottom {
      transform: translateX(1000px);
    }
    &.magic-word {
      .letter:not(:last-child) {
        margin-left: 0.25rem;
        border: none;
        font-weight: bold;
        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(8) {
          background-color: $color-correct;
        }
        &:nth-child(5),
        &:nth-child(6) {
          background-color: $color-missplaced;
        }
      }
    }
  }
  &.visible .word {
    opacity: 1;
  }
  @media only screen and (min-width: 1100px) {
    margin-top: 3rem;
    height: 42rem;
    min-height: 20rem;
    padding: 1rem 1rem 1rem 1rem;
  overflow-y: scroll;
  }
  @media only screen and (min-width: 450px) {
  padding: 0rem 1rem 2rem 1rem;
    .word {
      .letter {
        font-size: 0.85em;
        padding: 0.7rem;
      }
    }
  }
  @media only screen and (min-width: 630px) {
    .word .letter {
      font-size: 1em;
      padding: 0.9rem;
    }
  }
}

.loading {
  .result {
    justify-content: flex-start;
    height: unset;
    margin-top: 2rem;
    overflow-y: hidden;
    .word {
      margin-left: 1rem;
      margin-bottom: 0.75rem;
      opacity: 0.3;
      width: 60%;
      animation: pulse 1.5s infinite ease-in-out;
      &:nth-child(2n + 1){
        width: 50%;
        animation: pulse 2.5s infinite ease-in-out;
      }
      &:nth-child(3n) {
        width: 70%;
        animation: pulse 2s infinite ease-in-out;
      }
    }
  }
  @media only screen and (min-width: 1100px) {
    margin-top: unset;
  }
}

@include animation;
