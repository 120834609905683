.inputs {
  position: sticky;
  top: 0rem;
  z-index: 1;
  &.invisible {
    height: 2rem;
    width: 96%;
    margin-bottom: 1.5rem;
    .toggle-input-fields {
      span {
        display: inline-block;
      }
      path {
        transform: rotate(900deg);
      }
    }
    .inputs-content {
      animation: mymove 0.5s ease-in-out;
      padding: 1.5rem;
      * {
        opacity: 0;
        height: 0;
        margin: 0;
        padding: 0;
      }
    }
  }
  @keyframes mymove {
    0% {
      height: 20rem;
    }
    100% {
      height: 2rem;
    }
  }
  &-header {
    position: relative;
    z-index: 2;
    .toggle-input-fields {
      position: absolute;
      left: 0.5rem;
      top: 0.5rem;
      background-color: transparent;
      border: 2px solid $border-color;
      padding: 0.5rem;
      color: $font-color;
      transition: 0.2s ease-in-out;
      span {
        display: none;
        margin-left: 1rem;
      }
      svg {
        path {
          transform-origin: center;
          transition: 0.5s ease-in-out;
        }
      }
    }
  }
  &-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 1rem;
    padding: 0.5rem;
    max-width: 30rem;
    box-shadow: 1px 1px 15px 0px $black;

    background-color: $bg-color;
    transition: height 0.2s ease-in-out;
    input {
      border: 2px solid $border-color;
      width: 60%;
      margin: 0 auto;
      font-size: 1.5rem;
      padding: 0.25rem;
      text-transform: uppercase;
      text-align: center;
      background-color: transparent;
      color: $font-color;
      box-shadow: 1px 1px 15px 0px $black;
      transition: 0.2s ease-in-out;
      &.no-valid {
        background-color: $red !important;
        z-index: 100;
        animation: redShadowPulse 1.5s linear infinite;
        text-decoration: underline;
      }
      &:disabled {
        filter: saturate(0.25);
        opacity: 0.2;
      }
    }
    .positional {
      display: flex;
      width: 53%;
      justify-content: center;
      input {
        text-align: center;
        text-transform: uppercase;
        padding: 0;
        padding: 0.2rem;
        font-size: 1.75em;
        margin: 0.1rem;
        width: 2rem;
        height: 2rem;
        background-color: transparent;
        color: $font-color;
        &.has-value {
          background-color: $color-correct;
        }
      }
      &.incorrect {
        input {
          &.number-of-letters- {
            &-1 {
              font-size: 1.75rem;
            }
            &-2 {
              font-size: 1.5rem;
            }
            &-3 {
              font-size: 1rem;
            }
            &-4 {
              font-size: 0.75rem;
            }
            &-1,
            &-2,
            &-3,
            &-4 {
              background-color: $color-missplaced;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 630px) {
      padding: 1rem 1rem 2.5rem 1rem;
      .positional {
        input {
          font-size: 2.5em;
          width: 3rem;
          height: 3rem;
        }
        &.incorrect {
          input {
            &.number-of-letters- {
              &-1 {
                font-size: 2.5rem;
              }
              &-2 {
                font-size: 2rem;
              }
              &-3 {
                font-size: 1.5rem;
              }
              &-4 {
                font-weight: bold;
                font-size: 1rem;
              }
              &-1,
              &-2,
              &-3,
              &-4 {
                background-color: $color-missplaced;
              }
            }
          }
        }
      }
      input {
        padding: 0.75rem;
        font-size: 1.5em;
      }
    }
  }
}

.loading .inputs {
  opacity: 0.3;
  box-shadow: 1px 1px 2px 0px $black;
  animation: pulse 1.5s infinite ease-in-out;
  .inputs-content {
    background-color: transparent;
  }
  .positional, input {
    box-shadow: none;
    border: 2px solid rgba(255, 255, 255, 0.5);
  }
    .positional {
    opacity: 0.5;
    animation: pulse 1.5s infinite ease-in-out;
    height: 3rem;
  }
  .fake-label,
  label {
    opacity: 0;
  }
}

@include animation;
