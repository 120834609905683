@mixin animation {
  @keyframes pulse {
    0% {
      filter: contrast(0) brightness(0.5);
      background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
      filter: contrast(0) brightness(0.75);
      background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
      filter: contrast(0) brightness(0.5);
      background-color: rgba(165, 165, 165, 0.1);
    }
  }
  @keyframes redShadowPulse {
    0% {
      box-shadow: 0px 0px 3px red;
    }
    50% {
      box-shadow: 0px 0px 15px red;
    }
    100% {
      box-shadow: 0px 0px 3px red;
    }
  }
}

