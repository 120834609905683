header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.25rem;
  justify-content: center;

  .logo {
    width: 100%;
    margin: 1rem 1rem 0.25rem 1rem;
    display: flex;
    flex-direction: column;
    &-image {
      width: 20rem;
      height: auto;
      z-index: 10;
      transition: 0.2s ease-in-out;
      opacity: 0.7;
    }
    &-text {
      opacity: 0;
      height: 0;
      width: 0;
    }
    .logo-slogan {
      font-size: 1rem;
      color: $font-color--slogan;
      font-family: $font--slogan;
      text-align: left;
      margin: 0.25rem 0 0 0.5rem;
    }
  }
  @media only screen and (min-width: 665px) {
    justify-content: space-between;
    .logo {
      width: unset;
      margin-bottom: 1rem;
    }
  }
  .toggle-buttons {
    z-index: 10;
    button {
      margin: 0.5rem;
      padding: 0.25rem 0.5rem;
      font-size: 0.75em;
      background-color: $bg-color;
      color: $font-color;
      border: 2px solid $border-color;
      box-shadow: 1px 1px 15px 0px $black;
      transition: 0.2s ease-in-out;
      &.active {
        box-shadow: 0px 0px 9px $box-shadow-color--green;
        border-bottom-color: $border-color--green;
        offset-path: 2px $red;
        outline-style: dotted;
        outline-width: 3px;
        outline-color: $border-color--green;
        outline-offset: 3px;
      }
      &:active,
      &:focus,
      &:hover {
        box-shadow: 0px 0px 9px $box-shadow-color--green;
      }
      @media only screen and (min-height: 630px) {
        padding: 0.5rem 1rem;
        font-size: 1em;
      }
    }
  @media only screen and (min-width: 630px) {
    margin: 1rem;
  }
}
  h1,
  h2 {
    margin: 0;
  }
}

.loading header {
  opacity: 0.3;
  animation: pulse 1.5s infinite ease-in-out;
  .logo {
    opacity: 0.3;
    &-image {
      animation: pulse 1.5s infinite ease-in-out;
    }
    .logo-slogan {
      color: rgba(0,0,0,0);
      animation: pulse 1.5s infinite ease-in-out;
    }
  }
  @media only screen and (min-width: 665px) {
    justify-content: space-between;
    .logo {
      width: unset;
    }
  }
  .toggle-buttons {
    opacity: 0.2;
    button {
      animation: pulse 1.5s infinite ease-in-out;
      color: rgba(0,0,0,0);
      &.active {
        outline: none;
      }
      @media only screen and (min-height: 630px) {
        padding: 0.75rem 1.5rem;
        font-size: 1em;
      }
    }
  }
    margin: 0;
}
@include animation;
