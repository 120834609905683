footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: $font-color;
  background-color: $bg-color--footer;
  margin: -1rem;
  padding: 0.5rem 0 1.5rem 0;
  bottom: 0rem;
  position: fixed;
  left: 0;
  right: 0;
  box-shadow: 0px -10px 16px #00000057;
  font-size: 0.75rem;
  transition: 0.2s ease-in-out;
  .links {
    display: flex;
    align-items: center;
  }
  .made-by {
    word-break: keep-all;
    white-space: nowrap;
    display: flex;
    align-items: center;
    a {
      color: $font-color;
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

    a, span {
      margin: 0 0.5rem;
      color: $font-color;
    }
  @media only screen and (min-width: 500px) {
    font-size: 1rem;
    justify-content: space-evenly;
  }
}
.loading footer {
  opacity: 0.2;
  animation: pulse 1.5s infinite ease-in-out;
  color: rgba(0, 0, 0, 0);
  box-shadow: none;
  .made-by {
    opacity: 0.5;
    animation: pulse 1.5s infinite ease-in-out;
  }

  .links {
    a {
      opacity: 0.5;
      animation: pulse 1.5s infinite ease-in-out;
      color: rgba(0, 0, 0, 0);
    }
  }
}

@include animation;
